import {NgModule} from '@angular/core'
import {VerticalCardComponent} from './vertical-card/vertical-card.component'
import {AsyncPipe, NgClass, NgForOf, NgIf, NgOptimizedImage} from '@angular/common'
import {DynamicIconModule} from '../dynamic-icon/dynamic-icon.module'
import {DirectivesModule} from '../directives/directives.module'
import {IconCardComponent} from './icon-card/icon-card.component'
import {HorizontalCtaCardComponent} from './horizontal-cta-card/horizontal-cta-card.component'
import {ChipComponent} from '../chip/chip.component'
import {ImageCardComponent} from './image-card/image-card.component'
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome'
import {ButtonModule} from '../button/button.module'
import {HorizontalListCardComponent} from './horizontal-list-card/horizontal-list-card.component'
import {DashboardCardComponent} from './dashboard-card/dashboard-card.component'
import {DashboardCardBodyComponent} from './dashboard-card/dashboard-card-body/dashboard-card-body.component'
import {DashboardCardFooterComponent} from './dashboard-card/dashboard-card-footer/dashboard-card-footer.component'

@NgModule({
  declarations: [
    VerticalCardComponent,
    IconCardComponent,
    HorizontalCtaCardComponent,
    ImageCardComponent,
    HorizontalListCardComponent,
    DashboardCardComponent,
    DashboardCardBodyComponent,
    DashboardCardFooterComponent,
  ],
  exports: [
    VerticalCardComponent,
    IconCardComponent,
    HorizontalCtaCardComponent,
    ImageCardComponent,
    HorizontalListCardComponent,
    DashboardCardComponent,
    DashboardCardBodyComponent,
    DashboardCardFooterComponent,
  ],
  imports: [
    AsyncPipe,
    NgClass,
    DynamicIconModule,
    DirectivesModule,
    NgIf,
    ChipComponent,
    NgOptimizedImage,
    FontAwesomeModule,
    ButtonModule,
    NgForOf,
  ],
})
export class CardsModule {}
