import {ChangeDetectionStrategy, Component, Input} from '@angular/core'

/**
 * Content that can be specified through ng-content:
 *
 * content="action" -> displayed in the action section of the card
 */
@Component({
  selector: 'cft-horizontal-cta-card',
  templateUrl: './horizontal-cta-card.component.html',
  styleUrls: ['./horizontal-cta-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalCtaCardComponent {
  @Input() title!: string
  @Input() description!: string
  @Input() icon?: {name: string; collection: string}
  @Input() chip?: {colour: 'red' | 'blue'; text: string}
  @Input() elevation: 'flat' | 'elevated' = 'flat'
  @Input() separator = false
}
