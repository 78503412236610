import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  QueryList,
  ViewChildren,
} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faArrowRight} from '@fortawesome/pro-solid-svg-icons/faArrowRight'
import {Observable} from 'rxjs'
import {map, startWith} from 'rxjs/operators'
import {SystemColour} from '../../utils/colour.types'

@Component({
  selector: 'cft-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageCardComponent implements AfterViewInit {
  arrowIcon: IconDefinition = faArrowRight
  @Input() title!: string
  @Input() description?: string
  @Input() tags: string[] = []
  @Input() background?: ImageAsset
  @Input() profileImage?: ImageAsset
  @Input() brandLogo?: ImageAssetWithSize
  @Input() buttonId?: string
  @Input() buttonText?: string
  @Input() elevation: 'flat' | 'elevated' = 'elevated'
  @Input() chip?: {text: string; colour: SystemColour}

  @ViewChildren('transcludedDescription') transcludedDescription!: QueryList<ElementRef<HTMLDivElement>>

  hasDescription$!: Observable<boolean>

  constructor(private readonly cdr: ChangeDetectorRef) {}

  get elevated(): boolean {
    return this.elevation === 'elevated'
  }

  ngAfterViewInit(): void {
    this.hasDescription$ = this.transcludedDescription.changes.pipe(
      startWith(this.transcludedDescription),
      map((result: QueryList<ElementRef>) => !!result.length || !!this.description),
    )

    this.cdr.detectChanges()
  }
}

export interface ImageAsset {
  url: string
  title?: string
}

export interface ImageAssetWithSize {
  url: string
  title: string
  width: number
  height: number
}
