<div
  class="hover relative h-full cursor-pointer"
  [ngClass]="{'transform rounded-3 p-md shadow-1 transition-all duration-300 hover:shadow-2': elevation === 'elevated'}"
>
  <div class="flex h-full" [class.space-x-sm]="separator" [class.space-x-md]="!separator">
    <div class="square relative transform transition-all duration-300">
      @if (chip) {
        <div class="absolute right-0 top-0 z-10 mr-[-8px] mt-[-10px]">
          <cft-chip [text]="chip.text" [colour]="chip.colour" size="xs"></cft-chip>
        </div>
      }
      @if (icon) {
        <div class="text-2xl">
          <cft-dynamic-icon [icon]="icon"></cft-dynamic-icon>
        </div>
      }
    </div>
    @if (separator) {
      <div class="w-px shrink-0 bg-grey-100"></div>
    }
    <div class="flex flex-col justify-center">
      <div class="line-clamp-3 heading-base">{{ title }}</div>
      <div class="mt-2xs line-clamp-3 text-sm">{{ description }}</div>
      <div class="mt-auto pt-sm empty:hidden">
        <ng-content select="[content=action]"></ng-content>
      </div>
    </div>
  </div>
</div>
