import {ChangeDetectionStrategy, Component, Input} from '@angular/core'
import {IconDefinition} from '@fortawesome/fontawesome-svg-core'
import {faChevronRight} from '@fortawesome/pro-solid-svg-icons/faChevronRight'
import {SystemColour} from '../../utils/colour.types'
import {ImageAsset} from '../image-card/image-card.component'

@Component({
  selector: 'cft-horizontal-list-card',
  templateUrl: './horizontal-list-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HorizontalListCardComponent {
  readonly chevronIcon = faChevronRight

  @Input()
  icon?: {name: string; collection: string}

  @Input()
  image?: ImageAsset

  @Input()
  title!: string

  @Input()
  description?: string

  @Input()
  chip?: {icon?: IconDefinition; text?: string; colour?: SystemColour}

  @Input()
  elevation: 'flat' | 'elevated' = 'flat'

  @Input()
  ctaText?: string

  @Input()
  isIconCentered = true

  @Input()
  iconSize: 'sm' | 'md' | 'lg' = 'md'

  /**
   * When false, no hover style is applied
   */
  @Input()
  hoverEffect = true

  @Input()
  noActions = false

  @Input()
  isMultiLine = false
}
