<div
  class="card"
  cftAnimationState
  #animate="cftAnimationState"
  [ngClass]="{
    '-m-px-2 !border-2 !border-president-200 !bg-president-50': animate.animation === 'show',
    'shadow-1 hover:shadow-3': elevated,
    'zoom-image-on-hover hover:!bg-grey-50': !elevated
  }"
>
  <div>
    <div
      class="relative flex h-[11.25rem] items-center justify-center overflow-hidden bg-grey-50"
      [class.rounded-3]="!elevated"
      [class.hover:!rounded-b-none]="!elevated"
    >
      @if (background && background.url) {
        <img
          class="bg-image"
          [class.rounded-3]="!elevated"
          [class.rounded-t-3]="elevated"
          [ngSrc]="background.url"
          [alt]="background.title"
          sizes="25vw"
          fill
        />
      }
      @if (brandLogo && brandLogo.url) {
        <div class="absolute left-0 top-0 p-md">
          <div class="rounded-3 bg-white px-sm py-xs shadow-3" content="branding">
            <img
              [ngSrc]="brandLogo.url"
              [alt]="brandLogo.title"
              class="h-auto w-12.5"
              [width]="brandLogo.width"
              [height]="brandLogo.height"
              ngSrcset="100w"
            />
          </div>
        </div>
      }

      @if (chip) {
        <div class="absolute right-0 top-0 p-md">
          <cft-chip [size]="'sm'" [colour]="chip.colour" [text]="chip.text"></cft-chip>
        </div>
      }
    </div>

    @if (profileImage) {
      <div class="absolute mt-[-43px] flex w-full justify-between px-md">
        <div><!--todo google rating --></div>
        <img
          class="h-7.5 w-7.5 rounded-full border border-gold-500 object-cover"
          [ngSrc]="profileImage.url"
          [alt]="profileImage.title"
          [height]="60"
          [width]="60"
        />
      </div>
    }
    <div class="flex h-full flex-col px-md pb-lg pt-md">
      <div class="clamp !leading-snug heading-base">{{ title }}</div>
      <div class="mt-2xs">
        @for (tag of tags; track tag) {
          <cft-chip colour="president-lighter" class="mr-xs">{{ tag }}</cft-chip>
        }
      </div>
      <div class="clamp text-sm" [class.mt-xs]="hasDescription$ | async">
        @if (description) {
          {{ description }}
        }
        <div #transcludedDescription>
          <ng-content select="[content=description]"></ng-content>
        </div>
      </div>
      @if (buttonText) {
        <div class="mb-0 mt-auto pt-md">
          <button [attr.id]="buttonId" cft-button class="btn btn-secondary w-full text-base">
            {{ buttonText }}
            <fa-icon class="btn-right-icon" [icon]="arrowIcon"></fa-icon>
          </button>
        </div>
      }
    </div>
  </div>
</div>
