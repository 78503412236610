<div
  class="relative transform rounded-3 p-md"
  [class.cursor-pointer]="hoverEffect"
  [class.hover:bg-grey-100]="hoverEffect"
  [class.hover:shadow-2]="hoverEffect && elevation === 'elevated'"
  [ngClass]="{
    'shadow-1 transition-all duration-300': elevation === 'elevated',
    'border border-grey-200': elevation === 'flat'
  }"
>
  <div class="flex space-x-md">
    @if (icon) {
      <div
        [ngClass]="{
          'text-[30px]': iconSize === 'sm',
          'text-[50px]': iconSize === 'md',
          'text-[70px]': iconSize === 'lg'
        }"
        [class.self-center]="isIconCentered"
      >
        <cft-dynamic-icon [icon]="icon"></cft-dynamic-icon>
      </div>
    }
    @if (image) {
      <img
        class="h-[50px] w-[50px] min-w-[50px] rounded-2 object-cover"
        [ngSrc]="image.url"
        width="50"
        height="50"
        [alt]="image.title"
      />
    }
    <div class="flex grow items-center space-y-2xs">
      <div>
        <div class="line-clamp-1 !leading-[1.4] heading-sm">{{ title }}</div>
        @if (description) {
          <div
            [ngClass]="{
              'line-clamp-1': !isMultiLine
            }"
            class="text-xs"
          >
            {{ description }}
          </div>
        }
        @if (chip && chip.colour) {
          <div class="mt-xs">
            <cft-chip [icon]="chip.icon" [text]="chip.text" [colour]="chip.colour" size="sm"></cft-chip>
          </div>
        }
        @if (ctaText) {
          <div class="mt-xs line-clamp-1 text-sm font-medium">
            {{ ctaText }}
            <fa-icon class="ml-xs" [icon]="chevronIcon"></fa-icon>
          </div>
        }
      </div>
    </div>
    @if (!noActions) {
      <div class="flex items-center">
        <div #ref>
          <ng-content></ng-content>
        </div>
        @if (!ref.children.length) {
          <fa-icon class="ml-xs" [icon]="chevronIcon"></fa-icon>
        }
      </div>
    }
  </div>
</div>
