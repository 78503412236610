import {Component, Input} from '@angular/core'

@Component({
  selector: 'bgo-vertical-card',
  templateUrl: './vertical-card.component.html',
  styleUrls: ['./vertical-card.component.css'],
})
export class VerticalCardComponent {
  @Input() icon!: {name: string; collection: string}
  @Input() iconHeight: 'medium' | 'large' = 'medium'

  @Input() title!: string

  /** You can also provide a description using a child with `[content=description]`*/
  @Input() description?: string
  @Input() showDescriptionOnHover = false

  @Input() alignment: 'center' | 'left' = 'center'
}
