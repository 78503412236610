<div
  class="basic-card"
  [class.show-description]="!showDescriptionOnHover"
  [class.hover-show-description]="showDescriptionOnHover"
  cftAnimationState
  #animate="cftAnimationState"
  [ngClass]="{'-m-px-2 border-2 border-president-200 !bg-president-50': animate.animation === 'show'}"
>
  <div
    class="flex flex-grow flex-col items-center justify-center"
    [ngClass]="{
      'h-[8rem] text-[4rem]': iconHeight === 'medium',
      'h-[10.875rem] text-[6.875rem]': iconHeight === 'large'
    }"
  >
    <cft-dynamic-icon [icon]="icon"></cft-dynamic-icon>
  </div>
  <div class="title-box" [class.text-center]="alignment === 'center'" [class.text-left]="alignment === 'left'">
    <div class="title text-sm font-semibold">{{ title }}</div>
    <div class="description text-xs font-medium">
      @if (description) {
        <span>{{ description }}</span>
      }
      <ng-content select="[content=description]"></ng-content>
    </div>
  </div>
</div>
