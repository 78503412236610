<div
  class="card"
  [ngClass]="{
    'hover-call-to-action justify-center px-md pb-xs': layout === 'vertical',
    'pt-xl': layout === 'vertical' && size === 'sm',
    'pt-4': layout === 'vertical' && size === 'base',
    'show-call-to-action px-xs py-md': layout === 'horizontal',
    'bg-grey-50': color === 'default',
    'bg-white': color === 'white'
  }"
>
  <div [ngClass]="{'text-center': layout === 'vertical', 'flex items-center': layout === 'horizontal'}">
    <div
      [ngClass]="{
        'w-3 text-center text-xl': layout === 'horizontal',
        'vertical-icon-size-sm': layout === 'vertical' && size === 'sm',
        'vertical-icon-size-base': layout === 'vertical' && size === 'base'
      }"
    >
      @if (icon) {
        <cft-dynamic-icon [icon]="icon"></cft-dynamic-icon>
      }
    </div>
    <div [ngClass]="{'ml-md': layout === 'horizontal', 'mt-xs': layout === 'vertical'}">
      <div
        class="font-semibold"
        [innerHTML]="title"
        [class.text-sm]="size === 'sm' || layout === 'horizontal'"
        [class.text-base]="size === 'base'"
        [ngClass]="{
          'flex h-6 items-end justify-center': layout === 'vertical'
        }"
      ></div>
      <div
        [class.invisible]="!description"
        class="call-to-action mt-2xs h-2 text-xs text-president-400"
        [innerHTML]="description"
      ></div>
    </div>
  </div>
  <ng-content></ng-content>
</div>
