import {ChangeDetectionStrategy, Component, Input} from '@angular/core'

@Component({
  selector: 'cft-icon-card',
  templateUrl: './icon-card.component.html',
  styleUrls: ['./icon-card.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class IconCardComponent {
  @Input() title!: string
  @Input() icon?: {name: string; collection: string}
  @Input() description?: string
  @Input() layout: 'horizontal' | 'vertical' = 'vertical'
  @Input() size: 'base' | 'sm' = 'base'
  @Input() color: 'default' | 'white' = 'default'
}
