<div class="dashboard-card">
  <div class="header flex items-center justify-between border-b border-grey-300">
    <div class="flex h-7 items-center">
      @if (iconUrl) {
        <img alt="" [ngSrc]="iconUrl" width="28" height="28" />
      }
      <div class="plot-base" [class.ml-xs]="iconUrl">{{ title }}</div>
    </div>
  </div>
  <ng-content select="[content=actions]"></ng-content>
  <ng-content></ng-content>
</div>
