import {Component, Input} from '@angular/core'

@Component({
  selector: 'cft-dashboard-card',
  templateUrl: './dashboard-card.component.html',
  styleUrls: ['./dashboard-card.component.css'],
})
export class DashboardCardComponent {
  @Input()
  title!: string

  @Input() iconUrl?: string
}
